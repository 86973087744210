<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="review"
          :items="review"
          :itemHeader="reviewItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      review: [],
      reviewItemHeader: [
        { text: "product", value: "product_id.arabic_title" },
        { text: "name", value: "customer_id.first_name" },
        { text: "rating", value: "rating" },
        { text: "title", value: "title" },
        { text: "review", value: "review" },
        { text: "order", value: "order_id.id" },
        { text: "date", value: "date" },
        { text: "status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/review?fields=id,status,customer_id.first_name,customer_id.last_name,product_id.arabic_title,rating,review,title,order_id,order_id.id,date"
        );
        this.review = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
